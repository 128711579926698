import '@/css/app.css'
import Alpine from 'alpinejs'
import Headroom from 'headroom.js'
import 'fslightbox'

const headroom = () => {
    const headroom = new Headroom(document.querySelector('header'), {
        offset: 100
    }).init()
}

// App main
const main = async () => {
    headroom()
    // Dit komt overeen met de twig var blockHandle
    const blocks = ['text', 'storeFinder']
    const components = ['langSwitchter', 'swiper']

    const promises = []

    const importPromises = []

    for (const block of blocks) {
        const blockElements = document.querySelectorAll(`.${block}`)

        if (blockElements.length === 0) {
            continue
        }

        const importPromise = import(`./blocks/${block}.js`).then(
            ({ init }) => {
                blockElements.forEach((block) => {
                    importPromises.push(init(block))
                })
            }
        )

        importPromises.push(importPromise)
    }

    for (const comp of components) {
        const compElements = document.querySelectorAll(`.${comp}`)
        if (compElements.length === 0) {
            continue
        }

        const importPromise = import(`./components/${comp}.js`).then(
            ({ init }) => {
                compElements.forEach((comp) => {
                    importPromises.push(init(comp))
                })
            }
        )

        importPromises.push(importPromise)
    }
    importPromises.push(import('./menu.js').then(({ initMenu }) => initMenu()))
    importPromises.push(
        import('./animations.js').then(({ initAnimations }) => initAnimations())
    )
    return await Promise.all(importPromises)
}

document.addEventListener('DOMContentLoaded', async () => {
    main().then(() => {
        console.log('Vite <3')

        const anchor = getAnchor()
        if (anchor) {
            const anchorElement = document.querySelector(`#${anchor}`)
            if (anchorElement) {
                setTimeout(() => {
                    anchorElement.scrollIntoView({ behavior: 'smooth' })
                }, 350)
            }
        }

        Alpine.start()
    })
})

const getAnchor = () => {
    var currentUrl = document.URL,
        urlParts = currentUrl.split('#')

    return urlParts.length > 1 ? urlParts[1] : null
}

if (import.meta.hot) {
    import.meta.hot.accept(() => {
        console.log('HMR')
    })
}
